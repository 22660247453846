.row-title {
  font-family: Aldrich, sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  color: #fff;
  margin-top: 0.75rem;
}

.static {
  font-size: 1.5rem !important;
}

.sft-staking-status {
  color: white !important;
  .nav-tabs {
    left: 9%;
    border-bottom: solid 1px rgba(255, 255, 255, 0.4);

    .nav-link {
      background: transparent;
      color: white;
      width: 100%;
      border-top-left-radius: 0rem;
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }
  @media (max-width: 380px) {
    .nav {
      display: block !important;
    }
  }
}

.claim-reward {
  color: #000 !important;
  background-color: #fcee0c !important;
  margin-top: 0.5rem !important;
}

.claim-reward:hover {
  color: #000 !important;
  background-color: #fff !important;
}
