.navbar-toggler {
  background-color: white !important;
  border: 0 !important;
  border-radius: 0px !important;
  align-items: right !important;
  padding: 0.1rem 0.4rem !important;
}

.nav-item {
  margin: 0.5rem !important;

  button {
    height: 2.5rem !important;
  }
}

.navbar-links.active { 
  color: #e2333d !important;
}