.container-p {
    width: 100%;
    .container-z {
      max-width: 410px;
      border: 1px dashed;
      border-color: #fff;
      margin-right: auto;
      margin-left: auto;
    }
  
    h3 {
      margin-bottom: 0rem;
      font-size: 1rem !important;
      font-family: Rajdhani, sans-serif !important;
    }
  
    p {
      font-family: Rajdhani, sans-serif !important;
      font-size: 1rem !important;
      font-weight: 400 !important;
      text-transform: uppercase;
      font-style: normal;
      color: gold;
      margin-bottom: 0px !important;
    }

    .table th, .table td {
        padding: 0.5rem 0.25rem;
    }
  }